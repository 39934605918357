var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex-dir": "column",
      "background-color": "#ffffff"
    }
  }, [_c('HeaderComp', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('c-flex', [_c('c-link', {
          attrs: {
            "as": "router-link",
            "to": "/"
          }
        }, [_c('c-image', {
          attrs: {
            "src": require('@/assets/icon-chevron-left.svg'),
            "alt": "icon back"
          }
        })], 1), _c('h1', {
          directives: [{
            name: "chakra",
            rawName: "v-chakra"
          }],
          attrs: {
            "flex": "1",
            "ml": "15px"
          }
        }, [_vm._v(" Meal Plan ")])], 1)];
      },
      proxy: true
    }, {
      key: "additional-icon",
      fn: function fn() {
        return [_c('c-button', {
          attrs: {
            "variant": "ghost",
            "w": "24px",
            "h": "24px",
            "min-w": "24px",
            "min-h": "24px",
            "m": "0",
            "p": "0"
          },
          on: {
            "click": function click($event) {
              return _vm.$router.replace({
                name: 'meal-plan',
                query: {
                  intro: true
                }
              });
            }
          }
        }, [_c('c-image', {
          attrs: {
            "src": require('@/assets/icon-help-filled.svg'),
            "alt": "icon help"
          }
        })], 1)];
      },
      proxy: true
    }])
  }), _c('c-flex', {
    attrs: {
      "flex-dir": ['column', 'row-reverse'],
      "max-w": "1200px",
      "min-w": [null, '1200px'],
      "mx": [null, 'auto'],
      "mt": "20px"
    }
  }, [_c('RouterView'), _c('Navigation')], 1), _c('FooterComp'), _vm.showIntro && _vm.programId == null ? _c('PopupIntro', {
    on: {
      "close": function close($event) {
        _vm.showIntro = false;
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }