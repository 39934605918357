<template>
  <c-flex
    id="bottomNavigation"
    as="nav"
    :pos="['fixed', 'sticky']"
    :bottom="['0', 'normal']"
    left="0"
    :h="['60px', '100vh']"
    :w="['100vw', '360px']"
    bg="white"
    :align-items="['center', 'flex-start']"
    :justify-content="['space-around', 'normal']"
    d="flex"
    flex-shrink="0"
    :flex-direction="['row', 'column']"
    :z-index="['1000', 1]"
    :box-shadow="['4px -4px 50px 5px rgba(0, 0, 0, 0.15)', 'none']"
    :border-right="['none', '1px solid #F2F2F2']"
  >
    <portal
      v-if="isMenuActive"
      to="main"
    >
      <modal-menu />
    </portal>

    <c-flex
      v-if="isNutri && client != null"
      mt="25px"
      mb="30px"
    >
      <c-image
        h="72px"
        w="72px"
        border-radius="55px"
        src="https://via.placeholder.com/72"
        :alt="client.firstName"
      />
      <c-flex
        flex="1"
        flex-dir="column"
        ml="20px"
      >
        <c-text
          font-family="heading"
          font-size="18px"
          font-weight="600"
          line-height="27px"
        >
          {{ client.firstName }} {{ client.lastName }}
        </c-text>
        <c-text
          color="primary.400"
          font-size="14px"
          font-weight="400"
          line-height="21px"
          text-align="justified"
        >
          {{ client.phone }}
        </c-text>
      </c-flex>
    </c-flex>
    <template v-for="menu in menus">
      <c-link
        :key="menu.name"
        v-chakra="{
          '&.active': {
            bg: [null, '#f2f2f2'],
          },
        }"
        :as="menu.route != null ? 'router-link' : 'button'"
        :to="menu.route"
        :class="{ 'menu-item': true, active: menu.isActive }"
        exact-active-class="active"
        d="flex"
        :flex-dir="['column', 'row']"
        align-items="center"
        :justify-content="['center', 'flex-start']"
        :h="['auto', '66px']"
        :w="['auto', '328px']"
        :p="[0, '10px']"
        :my="[0, '5px']"
        :mr="[0, '10px']"
        :border-radius="[0, '12px']"
        @click="onMenuClicked(menu)"
      >
        <c-image
          class="icon"
          :alt="menu.name"
          :src="menu.icon"
          w="24px"
          h="24px"
        />
        <c-text
          v-chakra="{
            '&.active': {
              color: 'primary.400',
            },
          }"
          :ml="[0, '10px']"
          :class="{ active: menu.isActive }"
          :font-size="['10px', '16px']"
          font-weight="500"
          color="#c4c4c4"
        >
          {{ menu.name }}
        </c-text>
      </c-link>
    </template>
  </c-flex>
</template>

<script>
import ModalMenu from '@/components/modals/modal-menu.vue'

export default {
  name: 'NavBottom',
  components: {
    ModalMenu,
  },
  data() {
    return {
      isMenuActive: false,
    }
  },
  computed: {
    clientId() {
      return this.$route.params.clientId
    },
    client() {
      return this.$store.getters['clients/clientWithId'](this.clientId)
    },
    isNutri() {
      return (
        this.$route.matched
          .map((it) => it.meta)
          .reduce((res, it) => ({ ...res, ...it }), {}).isNutritionist ?? false
      )
    },
    menus() {
      const self = this
      return [
        {
          name: 'Profile Gizi',
          route: {
            name: self.getRouteName('meal-plan.profile-gizi'),
            query: this.$route.query,
          },
          get icon() {
            if (this.isActive)
              return require('@/assets/icon-clipboard-active.svg')
            return require('@/assets/icon-clipboard-grey.svg')
          },
          get isActive() {
            return (
              self.$route.name === self.getRouteName('meal-plan.profile-gizi')
            )
          },
        },
        {
          name: 'Rencana Makan',
          route: {
            name: self.getRouteName('meal-plan'),
            query: this.$route.query,
          },
          get icon() {
            if (this.isActive) {
              return require('@/assets/icon-calendar-active.svg')
            } else {
              return require('@/assets/icon-calendar-grey.svg')
            }
          },
          get isActive() {
            return self.$route.name === self.getRouteName('meal-plan')
          },
        },
        {
          name: 'Rekomendasi Menu',
          route: {
            name: self.getRouteName('meal-plan.rekomendasi-menu'),
            query: this.$route.query,
          },
          get icon() {
            if (this.isActive) {
              return require('@/assets/icon-menu-meat.svg')
            }
            return require('@/assets/icon-meat-grey.svg')
          },
          get isActive() {
            return (
              self.$route.name ===
              self.getRouteName('meal-plan.rekomendasi-menu')
            )
          },
        },
      ]
    },
  },
  methods: {
    onMenuClicked(menu) {
      if (menu.name === 'Menu') {
        this.isMenuActive = !this.isMenuActive
      }
    },
    getRouteName(name) {
      if (this.isNutri) return `nutri.${name}`
      return name
    },
  },
}
</script>
