var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('portal', {
    attrs: {
      "to": "main"
    }
  }, [_c('c-box', {
    attrs: {
      "bg": "#4f4f4fcc",
      "h": "100vh",
      "w": "100vw",
      "pos": "fixed",
      "top": "0",
      "left": "0",
      "z-index": "1001"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        $event.stopPropagation();
        return _vm.$emit('close');
      }
    }
  }), _c('c-flex', {
    attrs: {
      "bg": "white",
      "flex-dir": "column",
      "align-items": "center",
      "position": "fixed",
      "bottom": ['0px', 'auto'],
      "top": ['auto', '50%'],
      "left": ['auto', '50%'],
      "transform": ['initial', 'translate(-50%, -50%)'],
      "w": ['100vw', 'auto'],
      "h": ['100vh', 'auto'],
      "max-w": ['100vw', _vm.w],
      "max-h": ['100vh', _vm.h],
      "z-index": "1002",
      "border-radius": ['8px 8px 0 0', '8px'],
      "p": "16px"
    }
  }, [_c('c-flex', {
    attrs: {
      "w": "100%",
      "align-items": "flex-start"
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "ghost",
      "m": "0",
      "p": "0",
      "min-w": "24px",
      "min-h": "24px",
      "h": "24px",
      "w": "24px"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.$emit('close');
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-close-black.svg'),
      "alt": "icon close",
      "h": "24px",
      "w": "24px"
    }
  })], 1), _c('c-flex', {
    attrs: {
      "flex": "1",
      "ml": "15px"
    }
  }, [_vm._t("title")], 2)], 1), _c('c-flex', {
    attrs: {
      "flex-dir": "column",
      "align-items": "center",
      "mt": ['20px', 0]
    }
  }, [_vm._t("default")], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }