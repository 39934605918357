var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('popup-container', {
    attrs: {
      "max-w": 600
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-dir": "column",
      "max-width": "600px"
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-dir": "column",
      "align-items": "center",
      "mt": "20px",
      "px": "20px",
      "pb": "20px"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('./img-meal-plan-intro.svg'),
      "alt": "image intro illustration",
      "w": "200px"
    }
  }), _c('c-text', {
    attrs: {
      "font-weight": "bold",
      "font-size": "16px",
      "text-align": "center",
      "color": "#000000",
      "mt": "10px"
    }
  }, [_vm._v(" Yeay! Rencana makanmu sudah ada ")]), _c('ol', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "font-size": "14px",
      "color": "#4F4F4F",
      "mt": "10px",
      "mx": "20px"
    }
  }, [_c('li', [_vm._v("Rencana makanmu berlaku selama 2 - 4 minggu;")]), _c('li', [_vm._v(" Apabila mengalami perubahan berat badan atau kondisi kesehatan lainnya, maka rencana makanmu perlu diperbaharui; ")]), _c('li', [_vm._v(" Silakan hubungi kami kembali jika kamu membutuhkan rencana makan baru. ")])])], 1), _c('c-button', {
    attrs: {
      "variant": "outline",
      "variant-color": "primary",
      "border-radius": "24px",
      "h": "50px",
      "w": "122px",
      "m": "20px",
      "mb": "0",
      "align-self": "flex-end"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" Mengerti ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }