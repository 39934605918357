<template>
  <popup-container
    :max-w="600"
    @close="$emit('close')"
  >
    <c-flex
      flex-dir="column"
      max-width="600px"
    >
      <c-flex
        flex-dir="column"
        align-items="center"
        mt="20px"
        px="20px"
        pb="20px"
      >
        <c-image
          :src="require('./img-meal-plan-intro.svg')"
          alt="image intro illustration"
          w="200px"
        />
        <c-text
          font-weight="bold"
          font-size="16px"
          text-align="center"
          color="#000000"
          mt="10px"
        >
          Yeay! Rencana makanmu sudah ada
        </c-text>
        <ol
          v-chakra
          font-size="14px"
          color="#4F4F4F"
          mt="10px"
          mx="20px"
        >
          <li>Rencana makanmu berlaku selama 2 - 4 minggu;</li>
          <li>
            Apabila mengalami perubahan berat badan atau kondisi kesehatan
            lainnya, maka rencana makanmu perlu diperbaharui;
          </li>
          <li>
            Silakan hubungi kami kembali jika kamu membutuhkan rencana makan
            baru.
          </li>
        </ol>
      </c-flex>
      <c-button
        variant="outline"
        variant-color="primary"
        border-radius="24px"
        h="50px"
        w="122px"
        m="20px"
        mb="0"
        align-self="flex-end"
        @click.stop.prevent="$emit('close')"
      >
        Mengerti
      </c-button>
    </c-flex>
  </popup-container>
</template>

<script>
import PopupContainer from '@/components/modals/popup-container.vue'
export default {
  name: 'PopupIntroHealthPlan',
  components: { PopupContainer },
}
</script>
